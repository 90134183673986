import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Contact Center and Analytics Studio`}</h1>
    <p>{`Learn how to use CoreMedia Contact Center and Analytics Studio. Contact Center and Analytics Studio is a component of CoreMedia Experience Platform that allows you to manage your customers and your contact center.`}</p>
    <p>{`Contact Center and Analytics Studio is an application bundle that belongs to the CoreMedia Experience Platform ecosystem.
It allows you to manage your contact center and monitor your customers using up to date analytics.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "0",
          "marginRight": "0",
          "maxWidth": "630px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e8a788dbb34c6c862e66cd8594063365/48f8e/contcat-center-and-analytics-studio-login.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADqklEQVQ4yzXDiU9TBwDA4feH7UARBQ+cTJijogEcInhk4uQ+HDcUnIAttu+93if0ApSpUGKhBZxISyktBQTFubnMZYnZooD8Fo99ySd8UTVMmX6JK4Y4NbY4lZY4FZZlLslhvqy08nmFiZQqM3uqzeytMZNaa2FfnYV99RbSGizsf//ax4faXAjH2/2Mx+DuAiReQDC5y1gM7MG/Sa3U8dkVDSnlIinlWvZUaNlbqSW1SmRftUhajUharcj+TzM7BhFOdPmZWt5iMvGWmZW3hJJvmF7ZwfvwTw7UGEipEEmtlkirldlfJ5NeL5PRIJNxTebg/3/8OKt7EOHkT37CGzs8Xt9m7sn7W0Sf7XIv8oqMegN7q0UO1MnkdTs4e9NNYe/Ah+fUbgp6nXzVqudIs0xmi46cHhfC6d4JYs/fEdvcZvH5DonVLVaf7hJYekVmo5G0Oon0BglFl42Sfg/FaheFfU5KbrkpUg1wrF1H1qe5KjdCUd99Vn/bZmHzHdHNbZbX37C8sct49C+ONhlJbxDJbNZztEXHwUaRw80iR1pEMlsljrZJZHXIZHfpPzylcSOUdnl4HY6Q+HWLhafbRJ5tsfZyl+jaHxxvM5Far6FcN4xrMsJAaAnXdBz3TBzXTJyRRyt0+h7wtVIi94aBAtmDUNLt5d9hDxtLL1h8CfGNHV6//Iffn6yT3WHiUJOE4rqF71ROTvdayb9ppVBt40y/jaJbdgrUFhS9ek72GSgyehDOK128dtjYjG2QWHtGeH6VRPI5E5MBTnQayGjUUmW+w0BwEeODMOZAGEsggmUyjDMUpc07hqJPR36/kRKrF+Fso4mkQctiNERk7j6BWTejyTDaURU5HVqOtevJ6zFTKg5ySefmstFLmdlHmdnDVauXC3oHBRoDZ7RGLjp9CHnXBxhPhJjemGVqeYxgxEdwMcLPLhWKTonDrSINzlGG56K4ZuexBmawBqbxPgpzZz5Kz91x8rV6ivUmLrt9CLk3JAYjfkaTUcZX/UxEpwhEw4x4+lEoJbKUOoo1DmrsI1Q7hqmyealxeLjmHqHJd5tKp4tivZFSk4mrQ0MIxUqJ4C9+ppdjPF6bIxydYDYe4Z5XRa5SS3a3npxumeNdIt/ckFD0yeSpZE71y+RrZYp0Os6bjFwwG6m6PYSQ22FBNXwPzXiI0WAIXyCA5J9CbddzssfEt302Tqlt5GtsFEp2inR2zhkdlFocXLQ7+N7p4PKggx88Turvj/Afjwsi+oBDYLYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e8a788dbb34c6c862e66cd8594063365/e93cc/contcat-center-and-analytics-studio-login.webp 300w", "/static/e8a788dbb34c6c862e66cd8594063365/b0544/contcat-center-and-analytics-studio-login.webp 600w", "/static/e8a788dbb34c6c862e66cd8594063365/d869c/contcat-center-and-analytics-studio-login.webp 630w"],
              "sizes": "(max-width: 630px) 100vw, 630px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/e8a788dbb34c6c862e66cd8594063365/eed55/contcat-center-and-analytics-studio-login.png 300w", "/static/e8a788dbb34c6c862e66cd8594063365/7491f/contcat-center-and-analytics-studio-login.png 600w", "/static/e8a788dbb34c6c862e66cd8594063365/48f8e/contcat-center-and-analytics-studio-login.png 630w"],
              "sizes": "(max-width: 630px) 100vw, 630px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/e8a788dbb34c6c862e66cd8594063365/48f8e/contcat-center-and-analytics-studio-login.png",
              "alt": "login",
              "title": "login",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      